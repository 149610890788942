import { createApp } from 'vue'

import wx from 'weixin-js-sdk'

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

import router from './router'
import store from './store'
import './assets/index.scss'
import 'amfe-flexible'

import {setupInterceptors}  from './utils/request.js'
setupInterceptors(router)
// prismjs
import Prism from 'prismjs'
import'prismjs/themes/prism.css';// 你可以选择不同的主题



import App from './App.vue'

const app = createApp(App)
app.use(store)
app.use(Antd) 
app.use(wx)
app.use(Prism)
app.use(router)
app.mount('#app')
