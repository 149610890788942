<template>
  <RouterView />
</template>

<script setup>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

// console.log('呵呵')

// onMounted(() => {
//   if (!localStorage.getItem('access_token')) {
//     router.push({
//       path: '/login'
//     })
//   } else {
//     router.push({
//       path: '/chat'
//     })
//   }
// })
</script>

<style lang="scss" scoped></style>
