// api.js

import axios from 'axios';
import { message } from 'ant-design-vue';


const instance = axios.create({
//   baseURL: 'https://test.risk.nanwandt.com',
//   baseURL: process.env.VUE_APP_URL,
  baseURL: '/api',
  timeout: 60000,
  validateStatus: function (status) {
    return status >= 200 && status <= 500;
  },
  withCredentials: true
});

// 添加响应头
instance.interceptors.request.use(
  config => {
    // config.headers['content-type']="application/x-www-form-urlencoded"
    config.headers['content-type']="application/json;charset=utf-8"
    config.headers['Authorization'] = 'Basic c2FiZXI6c2FiZXJfc2VjcmV0';
    config.headers['Blade-Auth'] = 'bearer ' + localStorage.getItem('access_token');
        return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 添加响应头
export function setupInterceptors(router) {
  
  instance.interceptors.response.use(
    response => {
      response.headers['Access-Control-Allow-Origin'] = '*';
      if (response && response.data.code === 401) {
       localStorage.removeItem('access_token')
        if(localStorage.getItem('sess_key')){
          // 重定向到登录页面
          router.push({
            path: '/login',
            query: {
              sess_key: localStorage.getItem('sess_key')
            }
          })
        }else{
          router.push('/login')
        }
      }
      return response;
    },
    error => {
      return Promise.reject(error);
    }
  );
}

export default instance;
